import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Menu } from 'src/app/_shared/models';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() moduleMenus?: Menu;

  public extraParameter: any;
  panelId: string = 'dashboardsMenu';

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    // this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    this.globals.toggleSidebar = this.newInnerWidth < 1200;
  }
}
