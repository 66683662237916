import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  public preview: Subject<{ isShow: boolean, previewData: any }> = new Subject<{ isShow: boolean, previewData: any }>();

  constructor() { }

  show(content: any) {
    this.preview.next({ isShow: true, previewData: content });
  }

  hide() {
    this.preview.next({ isShow: false, previewData: '' });
  }
}
