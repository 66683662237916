import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpLoaderService {
  public loaderSubject: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  start() { this.loaderSubject.next(true); }

  stop() { this.loaderSubject.next(false); }
}
