import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/_shared/services/common.service';
import { PreviewService } from '../../services';


@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {

  @ViewChild('content') modalContent: ElementRef;

  constructor(
    private ngModel: NgbModal,
    private previewService: PreviewService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService
  ) { }

  modalOptions: NgbModalOptions = { size: 'xl' };

  document: any;

  ngOnInit(): void {
    this.previewService.preview.subscribe((result: { isShow: boolean, previewData: any }) => {
      if (result.isShow && result.previewData.DocumentPath) {
        this.document = {
          ...result.previewData,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(result.previewData.DocumentPath),
          ext: this.commonService.getFileExtension(result.previewData.DocumentPath)
        };
        this.openModal();
      }
    })
  }

  openModal() {
    this.ngModel.open(this.modalContent, this.modalOptions);
  }


}
