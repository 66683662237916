import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_modules/authentication/services';

@Component({
  selector: 'app-reditect-to-erp',
  templateUrl: './reditect-to-erp.component.html',
  styleUrls: ['./reditect-to-erp.component.scss']
})
export class ReditectToErpComponent implements OnInit {

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }


  onClickRedirect() {
    this.authService.redirectToErp().then(result => {
      const { redirectUrl } = result;
      (window as Window).open(redirectUrl, '_blank');
    });
  }

}
