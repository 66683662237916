import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    replaceCharList: ReadonlyArray<{ text: string, replaceWith: string }> = [
        {
            text: `(dd/MMM/yyyy)`,
            replaceWith: ''
        },
        {
            text: 'DD/MM/YYYY',
            replaceWith: ''
        },
        {
            text: "/",
            replaceWith: ""
        },
        {
            text: '(Marks out of 200 + Gallantry award score)',
            replaceWith: ''
        },
        {
            text: 'out of 200',
            replaceWith: ''
        },
        {
            text: '(up to 2 Digit)',
            replaceWith: ''
        },
        {
            text: ' ',
            replaceWith: '_'
        },
        {
            text: ".",
            replaceWith: "_"
        },
        {
            text: `'`,
            replaceWith: ''
        },
    ];

    constructor() { }

    isValidExcel(_file: File): boolean {
        return ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(_file.type);
    }

    replaceValue = (value: string): string => {
        this.replaceCharList.forEach(item => {
            if (value.includes(item.text)) {
                value = value.split(item.text).join(item.replaceWith);
            }
        })
        return value.split('_').filter(item => item).join('_').toLowerCase();
    }

    convertToJson(_file: File): Promise<any[]> {

        return new Promise((resolve, reject) => {

            const fileReader: FileReader = new FileReader();

            fileReader.onerror = () => reject('ERROR_IN_FILE_READER');

            fileReader.onload = (e) => {
                const binaryData = e.target?.result;

                const workBook: XLSX.WorkBook = XLSX.read(binaryData, { type: "binary", raw: true, cellDates: true, cellNF: false, cellText: false, dateNF: 'yyyy-mm-dd' });

                // CONVERT EXCEL SHEET TO JSON
                let dataList: any[] = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]], { raw: false, dateNF: 'yyyy-mm-dd', header: 1 })

                // GET HEADER LIST
                let headerList: { id: number, headerName: string }[] = (dataList[0] as Array<any>).map((head: any, i: number) => { return { id: i, headerName: head } });

                // REMOVE HEADER FROM DATA LIST
                dataList.splice(0, 1);

                // CONSTRUCT DATALIST

                let convertedJsonList: any[] = [];

                dataList.forEach((_data: Array<any>) => {

                    let jsonObj: any = {};

                    headerList.forEach(header => {

                        jsonObj[header.headerName.replace(`(dd/MMM/yyyy)`, '').replace("'", '').replace('-', '_').replace('(I/II/III)', '').trim().split('/').join('_').split(' ').join('_').split('.').join('_').split('___').join('_').split('__').join('_').split('(').join('').split(')').join('').toLowerCase()] = '';

                        _data.forEach((_d: any, index: number) => {

                            if (header.id === index) {
                                jsonObj[header.headerName.replace(`(dd/MMM/yyyy)`, '').replace("'", '').replace('-', '_').replace('(I/II/III)', '').trim().split('/').join('_').split(' ').join('_').split('.').join('_').split('___').join('_').split('__').join('_').split('(').join('').split(')').join('').toLowerCase()] = _d;
                            }
                        })
                    })
                    convertedJsonList.push(jsonObj);
                });
                resolve(convertedJsonList);
            }
            fileReader.readAsBinaryString(_file);
        });
    }

    convertMeritListToJson(_file: File): Promise<any[]> {

        return new Promise((resolve, reject) => {

            const fileReader: FileReader = new FileReader();

            fileReader.onerror = () => reject('ERROR_IN_FILE_READER');

            fileReader.onload = (e) => {
                const binaryData = e.target?.result;

                const workBook: XLSX.WorkBook = XLSX.read(binaryData, { type: "binary", raw: true, cellDates: true, cellNF: false, cellText: false, dateNF: 'yyyy-mm-dd' });

                // CONVERT EXCEL SHEET TO JSON
                let dataList: any[] = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]], { raw: false, dateNF: 'yyyy-mm-dd', header: 1 })

                // GET HEADER LIST
                let headerList: { id: number, headerName: string }[] = (dataList[0] as Array<any>).map((head: any, i: number) => { return { id: i, headerName: head } });

                // REMOVE HEADER FROM DATA LIST
                dataList.splice(0, 1);

                // CONSTRUCT DATALIST

                let convertedJsonList: any[] = [];

                dataList.forEach((_data: Array<any>) => {

                    let jsonObj: any = {};

                    headerList.forEach(header => {

                        jsonObj[this.replaceValue(header.headerName)] = '';

                        _data.forEach((_d: any, index: number) => {

                            if (header.id === index) {
                                jsonObj[this.replaceValue(header.headerName)] = _d;
                            }
                        })
                    })
                    convertedJsonList.push(jsonObj);
                });
                resolve(convertedJsonList);
            }
            fileReader.readAsBinaryString(_file);
        });
    }
}
