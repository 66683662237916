import { Injectable } from '@angular/core';
import { IMyDateModel } from 'angular-mydatepicker';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  getFileExtension(filename: string) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  // CONVERT DATE STRING TO IMyDateModel FOR DATE PICKER
  convertDate(_date: string = new Date().toISOString().substr(0, 10)): IMyDateModel {
    let _dateModel: IMyDateModel;

    try {
      const __date: string = new Date(_date).toLocaleDateString('en-gb').split('/').reverse().join('-');

      _dateModel = {
        isRange: false,
        singleDate: {
          jsDate: new Date(__date),
          formatted: __date.split('-').reverse().join('-')
        }
      };
    }
    catch (error) {
      _dateModel = {
        isRange: false,
        singleDate: {
          jsDate: new Date(),
          formatted: new Date().toISOString().split('T')[0].split('-').reverse().join('-')
        }
      };
    }

    return _dateModel;
  }

  // PARSE IMyDateModel TO DateString FOR API
  parseDate(date: IMyDateModel) {
    return date.singleDate.formatted.split('-').reverse().join('-');
  }

  // VALIDATE ALL REACTIVE FORMGROUP FIELDS
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.invalid && control.markAsTouched({ onlySelf: true });
      }
      else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }
}
