import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { AlertWidgetService, SpinnerService } from './_modules/widget/services';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {

    constructor(private spinner: SpinnerService, private router: Router, private alert: AlertWidgetService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //start loader service here
        this.spinner.show();

        return next.handle(req).pipe(
            retry(1),

            finalize(() => {
                //stop loader service here
                this.spinner.hide();
            }),

            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';

                //stop loader service here
                this.spinner.hide();

                if (error.error instanceof ErrorEvent) {
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                } else {
                    // server-side error
                    errorMessage = `Error: ${error?.error?.message}`;
                }

                switch (error.status) {
                    case 0:
                        this.alert.error("Something went wrong !", 3000);
                        break;
                    case 400:
                        this.alert.error(errorMessage, 3000);
                        break;
                    case 500:
                        this.alert.error(errorMessage, 3000);
                        break;
                    case 401:
                        this.alert.error(error.error?.message || "Unauthorized", 5000);
                        localStorage.clear();
                        this.router.navigate(['/authentication/sign_in'], { replaceUrl: true });
                        break;
                    default:
                        this.alert.error(error.error?.message);
                        break;
                }

                return throwError(error);
            })
        );
    }
}

