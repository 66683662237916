import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'INRCurrency'
})
export class INRCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) {
      return `₹ 0.00`
    }
    var result = value.toString().split('.');
    value = Math.round(parseInt(result[0]));
    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    if (otherNumbers != '' && otherNumbers != '-') {
      lastThree = ',' + lastThree;
    }
    var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    return `₹ ${output}${result.length > 1 ? '.' + result[1] : ''}`;
  }
}