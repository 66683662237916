import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './ui/page-title/page-title.component';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { SpinnerComponent } from './ui/spinner/spinner.component';
import { HttpLoaderComponent } from './ui/http-loader/http-loader.component';
import { PreviewModalComponent } from './ui/preview-modal/preview-modal.component';
import { AlertWidgetComponent } from './ui/alert-widget/alert-widget.component';


const _widget = [
  PageTitleComponent,
  SpinnerComponent,
  HttpLoaderComponent,
  PreviewModalComponent,
  AlertWidgetComponent
];

@NgModule({
  declarations: [..._widget],
  imports: [CommonModule, SharedModule],
  exports: [..._widget]
})
export class WidgetModule { }
