import { Component, OnInit } from '@angular/core';
import { Alert, AlertWidgetService } from '../../services';

@Component({
  selector: 'alert-widget',
  templateUrl: './alert-widget.component.html',
  styleUrls: ['./alert-widget.component.scss']
})
export class AlertWidgetComponent implements OnInit {

  alerts: Array<Alert> = [];

  message: string = "Data Saved Successfully."

  constructor(private alertWidgetService: AlertWidgetService) { }

  ngOnInit(): void {
    this.alertWidgetService.alert.subscribe(_alert => {
      this.alerts.push(_alert);
      setTimeout(() => this.remove(_alert), _alert.duration);
    })
  }

  remove(_alert: Alert) {
    this.alerts = this.alerts.filter(_ => _.id !== _alert.id);
  }

}
