import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChild, Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouteService implements CanActivateChild {
    constructor(private router: Router) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        if (!localStorage.getItem('authToken')) {
            localStorage.clear();
            this.router.navigate(['/authenticate/sign_in'], { replaceUrl: true });
            return false;
        }

        return true;
    }
}