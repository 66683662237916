import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

import * as Directives from './directives';
import * as Pipes from './pipes'
import * as Components from './components';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// import { CKEditorModule } from 'ckeditor4-angular';

@NgModule({
  declarations: [
    Directives.NumbersOnlyDirective,
    Directives.DigitsOnlyDirective,
    Directives.NameOnlyDirective,
    Pipes.FileSizePipe,
    Pipes.INRCurrencyPipe,
    Components.PageNotFoundComponent,
    Components.ImageUploaderComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    Ng2SearchPipeModule,
    NgSelectModule,
    AngularMyDatePickerModule,
    CKEditorModule
  ],
  exports: [
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    Ng2SearchPipeModule,
    NgSelectModule,
    AngularMyDatePickerModule,
    CKEditorModule,
    Directives.NumbersOnlyDirective,
    Directives.DigitsOnlyDirective,
    Directives.NameOnlyDirective,
    Pipes.FileSizePipe,
    Pipes.INRCurrencyPipe,
    Components.PageNotFoundComponent,
    Components.ImageUploaderComponent
  ],
  providers: [
    Pipes.FileSizePipe,
    Pipes.INRCurrencyPipe
  ]
})
export class SharedModule { }
