import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdmissionApiService } from 'src/app/_modules/admissions/services';
import { AuthenticationService } from 'src/app/_modules/authentication/services';
import { Router } from '@angular/router';



@Component({
  selector: 'app-session-box',
  templateUrl: './session-box.component.html',
  styleUrls: ['./session-box.component.scss']
})
export class SessionBoxComponent implements OnInit {
  sessions: Array<any> = [];

  sessionId: FormControl = new FormControl(localStorage.getItem('sessionId'));

  constructor(private apiService: AdmissionApiService, private authService: AuthenticationService,private router: Router) { }

  ngOnInit(): void {
    this.getSessions();
    this.sessionId.valueChanges.subscribe(sessionId => this.onChangeSession(sessionId));
  }

  getSessions() {
    this.apiService.get_sessions().then(sessions => {
      this.sessions = sessions;
    });
  }

  onChangeSession(sessionId: number) {
    var SessName = this.sessions.filter(a => a.SessionId == sessionId)[0].SessionName;
    this.authService.doSessionChange({ SessionId: sessionId, SessionName: SessName }).then(result => {
      localStorage.clear();
      this.authService.saveJwtTokens(result.data);
      this.router.navigate(['/app/_modules/admissions/components/counselling']); 
    });
  }
}
