import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from '../../services';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() show: boolean = false;

  constructor(private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.spinner.subject.subscribe((status: boolean) => this.show = status);
  }


}
