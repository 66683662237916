import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_modules/authentication/services';
import { ThemeOptions } from '../../../../../theme-options';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit {

  _username: string = localStorage.getItem('name') || "";
  _email: string = localStorage.getItem('email') || "";
  _photoUrl: string = localStorage.getItem('photoUrl') || "";

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  signOut() {
    this.authService.signOut().then();
    localStorage.clear();
    this.router.navigate(['/authentication/sign_in'], { replaceUrl: true });
  }

  setDefault(e: any) {
    this._photoUrl = '../../../../../../assets/images/user.png';
  }
}
