<!-- Second Design -->

<div class="app-container app-theme-white body-tabs-shadow">
    <div class="app-container">
        <div class="h-100 login-special">


            <div class="mx-auto app-login-box col-md-4">
                <div class="app-logo mb-0"></div>
                <h4 class="mb-0">
                    404
                </h4>
                <h4 class="mb-0 pt-0">
                    Oops !!! <br /> The page you are looking for is not found.
                </h4>
            </div>
        </div>
    </div>
</div>