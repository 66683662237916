<div class="d-flex">
	<div class="header-btn-lg pe-0 m-0">
		<div class="widget-content p-0">
			<div class="widget-content-wrapper">
				<div class="widget-content-left">
					<div class="btn-group" ngbDropdown placement="bottom-right">
						<button type="button" class="btn btn-link p-0" ngbDropdownToggle>
							<span class="d-flex">
								<div class="icon-wrapper icon-wrapper-alt rounded-circle">
									<img width="42" [src]="_photoUrl" alt="photo" class="rounded-circle"
										(error)="setDefault($event)">
								</div>
								<div class="user-info">
									<span class="_un">{{ _username }}</span>
									<br />
									<span class="_ue">{{ _email }}</span>
								</div>
							</span>
						</button>
						<div ngbDropdownMenu>
							<!-- <button class="dropdown-item">
								<i class="fas fa-cog"></i> Settings
							</button> -->
							<button class="dropdown-item" (click)="signOut()">
								<i class="fas fa-sign-out-alt"></i> Sign Out
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>