import { combineReducers } from 'redux';
import { ConfigReducer } from './config.reducer';

export class AIOLUIState {
  config: any;
};

export const rootReducer = combineReducers<AIOLUIState>({
  config: ConfigReducer,
});


