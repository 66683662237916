import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { timeout } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  queryParams: any = {};

  constructor(private httpClient: HttpClient) {
    // console.log(window.navigator.onLine ? "Internet Connection Enabled" : "Internet Connection Disabled");
  }

  /**
   *  Perform a GET request to the server.
   *
   * @param endpoint
   * @param params    optional
   * @returns
   */
  async GET(endpoint: string, params: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.queryParams = params;

        let result = this.httpClient.get(ApiService.getApiUrl() + endpoint, await this.buildRequestOptions()).pipe();

        result.subscribe(async (response: any) => {
          resolve(response.data);
        });
      } catch (e) {
        console.log(`CAUGHT EXCEPTION IN 'GET' REQUEST: `, e);
        reject(null);
        return;
      }
    });
  }

  /**
   * Perform a POST request to the server.
   *
   * @param endpoint
   * @param payload       default null
   * @param withTimeout   default true
   * @returns
   */
  async POST(endpoint: string, payload: any | FormData = null, isFormData: boolean = false, withTimeout = true): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let result: Observable<any>;
      try {
        if (isFormData) {
          result = await this.httpClient.post(ApiService.getApiUrl() + endpoint, payload, { headers: await this.buildHeaders(false), reportProgress: true }).pipe();
        }
        else {
          result = await this.httpClient.post(ApiService.getApiUrl() + endpoint, JSON.stringify(payload), await this.buildRequestOptions()).pipe();
        }

        result.subscribe(async (response: any) => {
          resolve(response);
          return;
        });
      } catch (e) {
        console.log(`CAUGHT EXCEPTION IN 'POST' REQUEST: `, e);
        reject(null);
        return;
      }
    });
  }

  /**
   * Perform a PUT request to the server.
   *
   * @param endpoint
   * @param data          default null
   * @param withTimeout   default true
   * @returns
   */
  async PUT(
    endpoint: string,
    data: any = null,
    withTimeout = true
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let result: Observable<any>;
      try {
        result = await this.httpClient
          .put(
            ApiService.getApiUrl() + endpoint,
            data,
            await this.buildRequestOptions()
          )
          .pipe(timeout(withTimeout ? 15000 : 60000));
        result.subscribe(async (response: any) => {
          resolve(response);
          return;
        });
      } catch (e) {
        console.log(`CAUGHT EXCEPTION IN 'PUT' REQUEST: `, e);
        reject(null);
        return;
      }
    });
  }

  /**
   * Perform a DELETE request to the server.
   *
   * @param endpoint
   * @param withTimeout   default true
   * @returns
   */
  async DELETE(endpoint: string, withTimeout = true): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let result: Observable<any>;
      try {
        result = await this.httpClient
          .delete(
            ApiService.getApiUrl() + endpoint,
            await this.buildRequestOptions()
          )
          .pipe(timeout(withTimeout ? 15000 : 60000));

        result.subscribe(async (response: any) => {
          resolve(response);
          return;
        });
      } catch (e) {
        console.log("Caught exception in DELETE request: ", e);
        reject(null);
        return;
      }
    });
  }

  async loadJSON(path: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let result: Observable<any>;

      try {
        result = await this.httpClient.get(path).pipe();
        result.subscribe(async (response: any) => {
          resolve(response);
          return;
        });
      } catch (e) {
        console.log("Caught exception in JSON request: ", e);
        reject(null);
        return;
      }
    });
  }

  /**
   * Genearate the request options for all httpclient call.
   */
  private async buildRequestOptions() {
    return {
      headers: await this.buildHeaders(),
      params: await this.buildQueryParams(),
    };
  }

  /**
   * Generate the headers that are required for all the requests.
   */
  private async buildHeaders(addContentTye: boolean = true) {
    let headers: { Authorization?: string; "Content-Type"?: string } = {
      // REMOVE "CONTENT-TYPE" IN CASE OF FORMDATA
      ...(addContentTye && { "Content-Type": environment.content_type }),

      // ADD AUTH TOKEN IF EXISTS
      ...(localStorage.getItem("authToken") && { Authorization: "Bearer " + localStorage.getItem("authToken") })
    };

    return new HttpHeaders(headers);
  }

  httpHeader() {
    let headers: { Authorization?: string } = {
      ...(localStorage.getItem("authToken") && {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };

    return new HttpHeaders(headers);
  }

  /**
   * GENERATE QUERY PARAMS
   * @returns HttpParams
   */
  private async buildQueryParams() {
    debugger;
    let httpParams = new HttpParams();
    Object.keys(this.queryParams).forEach((key) => {
      httpParams = httpParams.append(key, this.queryParams[key]);
    });
    return httpParams;
  }

  /**
   * Get the api url to use through different environment strategies.
   */
  private static getApiUrl(): string {
    return environment.api_service_url;
  }
}
