import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_shared/services';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private apiService: ApiService) { }

  async _doGoogleAuth(data: any) {
    return await this.apiService.POST(ApiEndpoint.google_auth, data);
  }
  /**
   * Save the JWT and extract the relevant data from it.
   */
  public async saveJwtTokens(data: any) {
    try {
      Object.keys(data).map(key => {
        if (['authToken', 'email', 'googleId', 'name', 'idToken', 'photoUrl', 'sessionId', 'sessionName'].includes(key)) {
          localStorage.setItem(key, data[key]);
        }
      });

      return true;
    }
    catch (e) {
      console.log(e);

      // Something went wrong, undo setting of login data as it is incomplete.
      await localStorage.clear();
      return false;
    }
  }

  async signOut() {
    return await this.apiService.POST(ApiEndpoint.sign_out);
  }

  async redirectToErp() {
    return await this.apiService.GET(ApiEndpoint.redirect_to_erp);
  }

  async doSessionChange(data: any) {
    return await this.apiService.POST(ApiEndpoint.change_session, data);
  }
}

export enum ApiEndpoint {
  /**
   * @Api : authenticate/google_auth
   * @method : post
   * @description : for saving user details
   * @returns {Object}
   */
  google_auth = 'authenticate/google_auth',

  /**
   * @Api : authenticate/google_auth
   * @method : post
   * @description : for saving user details
   * @returns {Object}
   */
  sign_out = 'authenticate/sign_out',

  /**
   * @Api : authenticate/google_auth
   * @method : post
   * @description : for saving user details
   * @returns {Object}
   */
  redirect_to_erp = 'authenticate/redirect_to_erp',

  /**
   * @Api : authenticate/google_auth
   * @method : post
   * @description : for saving user details
   * @returns {Object}
   */
  change_session = 'authenticate/change_session',
}
