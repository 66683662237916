<div class="app-page-title pt-3 pb-3 mb-0">
	<div class="page-title-wrapper">
		<div class="page-title-heading">
			<div class="page-title-icon">
				<i class="icon icon-gradient bg-love-kiss {{icon}}"></i>
			</div>
			<div>
				{{heading}}
				<div class="page-title-subheading opacity-10">
					<nav class="" aria-label="breadcrumb">
					  <ol class="breadcrumb">
						<li class="breadcrumb-item">
						  <a><i aria-hidden="true" class="fa fa-home"></i></a>
						</li>
						<li class="breadcrumb-item" *ngFor="let _b of breadcrumbs">
						  <a>{{ _b }}</a>
						</li>
						<!-- <li class="breadcrumb-item">
						  <a>Import Registration</a>
						</li> -->
					  </ol>
					</nav>
				  </div>
				<!-- <div class="page-title-subheading">{{subheading}}</div> -->
			</div>
		</div>
		<!-- <div class="page-title-actions">
			<button type="button" class="btn-shadow me-3 btn btn-dark">
				<fa-icon [icon]="faStar"></fa-icon>
			</button>
			<button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
				<fa-icon [icon]="faPlus" class="me-2"></fa-icon>
				Create New
			</button>
		</div> -->
	</div>
</div>