<div [ngClass]="{
	'closed-sidebar' : globals.toggleSidebar,
	'closed-sidebar-md' : globals.toggleSidebarMobile,
	'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
	'header-menu-open' : globals.toggleHeaderMobile,
	'fixed-footer' : globals.toggleFixedFooter
	}" [class]="'app-container app-theme-white'">
	<app-header></app-header>
	<app-sidebar [moduleMenus]="moduleMenus"></app-sidebar>
	<div class="app-main__outer">
		<div class="app-main__inner">
			<router-outlet #o="outlet"></router-outlet>
		</div>
		<app-footer></app-footer>
	</div>
	<div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
