import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Alert {
  id?: string,
  message: string,
  type: 'success' | 'error' | 'info' | 'warnning',
  duration?: number
}

@Injectable({
  providedIn: 'root'
})
export class AlertWidgetService {
  public alert: Subject<Alert> = new Subject<Alert>();

  constructor() { }

  success(message: string, duration: number = 3000) {
    this.alert.next({
      id: new Date().getTime().toString(),
      message: message,
      duration: duration,
      type: 'success'
    })
  }

  error(message: string, duration: number = 3000) {
    this.alert.next({
      id: new Date().getTime().toString(),
      message: message,
      duration: duration,
      type: 'error'
    })
  }
}
