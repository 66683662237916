<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
	<div class="logo-src" [ngClass]="{'logo-src-dashboard': globals.showHeaderIcon }" *ngIf="globals.showHeaderIcon">
	</div>
	<div class="logo-text" *ngIf="globals.showHeaderIcon">
		<h5>AIL</h5>
		<p>Army Institute Of Law</p>
	</div>
	<div class="app-header__content">
		<div class="app-header-left">
			<!-- <app-search-box></app-search-box> -->
			<app-session-box></app-session-box>
			<app-reditect-to-erp></app-reditect-to-erp>
		</div>
		<div class="app-header-right">
			<app-module-box></app-module-box>
			<app-user-box></app-user-box>
		</div>
	</div>
	<div class="app-header__mobile-menu">
		<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
			[ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
	</div>
	<div class="app-header__menu">
		<span>
			<button class="btn-icon btn-icon-only btn btn-primary"
				[ngClass]="{'is-active' : globals.toggleHeaderMobile}" (click)="toggleHeaderMobile()">
				<div class="btn-icon-wrapper">
					<fa-icon [icon]="faEllipsisV"></fa-icon>
				</div>
			</button>
		</span>
	</div>
</div>