import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_shared/services';

export enum AdmissionAPIEndPoint {
  /**
   * @Api : Search Registration
   * @method : GET
   * @description : Search Student Registration
   * @returns {Object}
   */
  SEARCH_REGISTRATION = 'admission/search_registration',

  /**
  * @Api : Registration Category
  * @method : GET
  * @description : Get Category
  * @returns {Object}
  */
  REGISTRATION_CATEGORY = 'admission/registration_category',

  /**
  * @Api : GET REGISTRATION DOCUMENTS
  * @method : GET
  * @description : For upload data in API
  * @returns {Object}
  */
  GET_COUNSELLINGS_DOCUMENTS = 'admission/get_counsellings_documents',

  /**
  * @Api : GET STUDNET REGISTRATION INFO
  * @method : GET
  * @description : For upload data in API
  * @returns {Object}
  */
  GET_STUDENT = 'admission/get_student',

  /**
  * @Api : SAVE COUNSELLINGS TAB 1 STUDNET REGISTRATION INFO
  * @method : POST
  * @description : For upload data in API
  * @returns {Object}
  */
  SAVE_COUNSELLING_STEP = 'admission/counsellings/save_counselling_step',

  /**
  * @Api : SAVE COUNSELLINGS TAB 1 STUDNET REGISTRATION INFO
  * @method : POST
  * @description : For upload data in API
  * @returns {Object}
  */
  SAVE_STUDENTINFO_STEP = 'admission/counsellings/save_student_information_step',

  /**
 * @Api : SAVE COUNSELLINGS TAB 1 STUDNET REGISTRATION INFO
 * @method : POST
 * @description : For upload data in API
 * @returns {Array}
 */
  GET_CLASS_LIST = 'common/get_class',

  /**
 * @Api : SAVE COUNSELLINGS TAB 1 STUDNET REGISTRATION INFO
 * @method : POST
 * @description : For upload data in API
 * @returns {Array}
 */
  GET_BLOOD_GROUP = 'common/get_blood_groups',

  /**
    * @Api : SAVE COUNSELLINGS TAB 5 STUDNET REGISTRATION INFO
    * @method : POST
    * @description : For upload data in API
    * @returns {Object}
    */
  SAVE_DECLARATION_STEP = 'admission/counsellings/save_declaration_step',

  /**
    * @Api : SAVE COUNSELLINGS TAB 5 STUDNET REGISTRATION INFO
    * @method : POST
    * @description : For upload data in API
    * @returns {Object}
    */
  SAVE_PAYMENT_STEP = 'admission/counsellings/save_payment_step',

  /**
  * @Api : SAVE COUNSELLINGS TAB 5 STUDNET REGISTRATION INFO
  * @method : POST
  * @description : For upload data in API
  * @returns {Object}
  */
  SAVE_ICARD_STEP = 'admission/counsellings/save_idcard_step',

  /**
  * @Api : SAVE COUNSELLINGS STUDNET REGISTRATION INFO
  * @method : POST
  * @description : For upload data in API
  * @returns {Object}
  */
  SAVE_COUNSELLING = 'admission/save_counselling',

  /**
  * @Api : SAVE COUNSELLINGS TAB 5 STUDNET REGISTRATION INFO
  * @method : POST
  * @description : For upload data in API
  * @returns {Object}
  */
  SAVE_PAYMENTINFO = 'admission/payment',

  /**
 * @Api : GET STUDENT DOCUMENTS
 * @method : POST
 * @description : GET STUDENT DOCUMENT
 * @returns {Object}
 */
  GET_STUDENT_DOCUMENTS = 'admission/student_document',

  /**
 * @Api : GET STUDENT DOCUMENTS
 * @method : POST
 * @description : GET STUDENT DOCUMENT
 * @returns {Object}
 */
  RECEIVE_DOCUMENRT = 'admission/student_document',

  /**
 * @Api : GET STUDENT DOCUMENTS
 * @method : POST
 * @description : GET STUDENT DOCUMENT
 * @returns {Object}
 */
  UPLOAD_IDCARD_IMAGES = 'admission/upload_idcards',

  /**
 * @Api : GET STUDENT DOCUMENTS
 * @method : POST
 * @description : GET STUDENT DOCUMENT
 * @returns {Object}
 */
  GET_SESSIONS_LIST = 'common/get_sessions',
}

@Injectable({
  providedIn: 'root'
})
export class AdmissionApiService {

  constructor(private apiService: ApiService) { }

  async search(params?: any) {
    return await this.apiService.GET(AdmissionAPIEndPoint.SEARCH_REGISTRATION + `?IsCounselling=false&IsPayment=false&SessionId=${params}`);
  }

  async get_category() {
    return await this.apiService.GET(AdmissionAPIEndPoint.REGISTRATION_CATEGORY);
  }

  async get_documents(params: any) {
    return await this.apiService.GET(AdmissionAPIEndPoint.GET_COUNSELLINGS_DOCUMENTS, params);
  }

  async get_student(studnetId: any) {
    return await this.apiService.GET(`${AdmissionAPIEndPoint.GET_STUDENT}/${studnetId}`);
  }

  async save_counselling_step(data: any) {
    return await this.apiService.POST(AdmissionAPIEndPoint.SAVE_COUNSELLING_STEP, data);
  }

  async save_student_info_step(data: any) {
    return await this.apiService.POST(AdmissionAPIEndPoint.SAVE_STUDENTINFO_STEP, data);
  }

  async get_classes() {
    return await this.apiService.GET(AdmissionAPIEndPoint.GET_CLASS_LIST);
  }

  async get_bloodgroup() {
    return await this.apiService.GET(AdmissionAPIEndPoint.GET_BLOOD_GROUP);
  }

  async save_icard_issue_step(data: any) {
    return await this.apiService.POST(AdmissionAPIEndPoint.SAVE_ICARD_STEP, data);
  }

  async save_declaration_step(studentId: number) {
    return await this.apiService.POST(`${AdmissionAPIEndPoint.SAVE_DECLARATION_STEP}/${studentId}`);
  }

  async save_payment_step(data: any) {
    return await this.apiService.POST(AdmissionAPIEndPoint.SAVE_PAYMENT_STEP, data, true);
  }

  async save_counselling(data: any) {
    return await this.apiService.POST(AdmissionAPIEndPoint.SAVE_COUNSELLING, data);
  }

  async save_payment(data: any) {
    return await this.apiService.POST(AdmissionAPIEndPoint.SAVE_PAYMENTINFO, data);
  }

  async get_student_documents(studentId: number) {
    return await this.apiService.GET(`${AdmissionAPIEndPoint.GET_STUDENT_DOCUMENTS}/${studentId}`);
  }

  async receive_documents(data: any) {
    return await this.apiService.POST(`${AdmissionAPIEndPoint.GET_STUDENT_DOCUMENTS}/${data.StudentId}/receive_documents`, data);
  }

  async return_documents(data: any) {
    return await this.apiService.POST(`${AdmissionAPIEndPoint.GET_STUDENT_DOCUMENTS}/${data.StudentId}/return_documents`, data);
  }

  async upload_idcard_images(data: FormData) {
    return await this.apiService.POST(`${AdmissionAPIEndPoint.UPLOAD_IDCARD_IMAGES}`, data, true);
  }

  async get_sessions() {
    return await this.apiService.GET(AdmissionAPIEndPoint.GET_SESSIONS_LIST);
  }
}