<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ document.UploadedDocumentName }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <embed [src]="document.url" type="application/pdf" style="width: 100%; height: 628px;" *ngIf="document.ext == 'pdf'">
    <img [src]="document.url" alt="doc" *ngIf="document.ext !== 'pdf'" style="display: block; width: 100%;">
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div> -->
</ng-template>