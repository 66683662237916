import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';
import { ThemeOptions } from '../../theme-options';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions
  ) { }

  ngOnInit(): void {
    this.globals.showHeaderIcon = true;
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

}
