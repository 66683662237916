<div
	[class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme"
	(mouseover)="sidebarHover()"
	(mouseout)="sidebarHover()">
	<div class="app-header__logo">
		<div class="logo-src"></div>
		<div  class="logo-text closed">
			<h5>AIL</h5>
			<p>Army Institute Of Law</p>
		</div>
		<div class="header__pane ms-auto">
			<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
				[ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
				<span class="hamburger-box">
					<span class="hamburger-inner"></span>
				</span>
			</button>
		</div>
	</div>
	<div class="app-sidebar-content">
		<div class="app-sidebar-scroll">
			<perfect-scrollbar>
				<div class="v-sidebar-menu vsm-default">
					<div class="vsm-list">
						<ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="panelId">
							<!-- <ngb-panel>
								<ng-template ngbPanelHeader>
									<div class="vsm-header">Student Management</div>
								</ng-template>
							</ngb-panel> -->
							<ngb-panel [id]="panelId">
								<ng-template ngbPanelHeader>
									<button ngbPanelToggle class="vsm-link">
										<i class="vsm-icon pe-7s-rocket"></i>
										<span class="vsm-title">{{ moduleMenus?.title }}</span>
										<i class="vsm-arrow"></i>
									</button>
								</ng-template>
								<ng-template ngbPanelContent>
									<div class="vsm-dropdown">
										<div class="vsm-list">
											<div class="vsm-item m-margin" *ngFor="let menu of (moduleMenus?.children || [])">
												<a
													[routerLink]="[menu?.url]"
													routerLinkActive="active-item"
													[routerLinkActiveOptions]="{exact: true}" class="vsm-link">
													<span class="vsm-title">{{ menu?.title }}</span>
												</a>
											</div>
											<!-- <div class="vsm-item">
												<a 
													routerLinkActive="active-item" 
													routerLink="/lte/registration-list" 
													[routerLinkActiveOptions]="{exact: true}"
													class="vsm-link">
													<span class="vsm-title">Register Boxed</span>
												</a>
											</div> -->
										</div>
									</div>
								</ng-template>
							</ngb-panel>
							<!-- <ngb-panel id="pagesMenu">
								<ng-template ngbPanelHeader>
								<button ngbPanelToggle class="vsm-link">
									<i class="vsm-icon pe-7s-browser"></i>
									<span class="vsm-title">Pages</span>
									<i class="vsm-arrow"></i>
								</button>
								</ng-template>
								<ng-template ngbPanelContent>
								<div class="vsm-dropdown">
									<div class="vsm-list">
									<div class="vsm-item">
										<a routerLinkActive="active-item" routerLink="/pages/login-boxed" class="vsm-link"><span
										class="vsm-title">Login Boxed</span></a>
									</div>
									<div class="vsm-item">
										<a routerLinkActive="active-item" routerLink="/pages/register-boxed" class="vsm-link"><span
										class="vsm-title">Register Boxed</span></a>
									</div>
									<div class="vsm-item">
										<a routerLinkActive="active-item" routerLink="/pages/forgot-password-boxed"
										class="vsm-link"><span class="vsm-title">Forgot Password Boxed</span></a>
									</div>
									</div>
								</div>
								</ng-template>
							</ngb-panel> -->
						</ngb-accordion>
					</div>
				</div>
			</perfect-scrollbar>
		</div>
	</div>
</div>