import { Injectable } from "@angular/core";
import { Menu } from "../models";

@Injectable({ providedIn: 'root' })
export class ModuleService {
    get modulesMenu() {
        let menu_list: Menu[] = [
            {
                id: 1,
                title: "Dashboard",
                type: "item",
                icon: "",
                url: "/",
                visibility: true,
                expand: false
            },
            {
                id: 2,
                title: "Law Entrance Test",
                type: "group",
                icon: "",
                url: "/let",
                visibility: true,
                expand: true,
                children: [
                    {
                        id: 2.1,
                        title: "Upload Merit List",
                        type: "item",
                        url: "/let/upload-registration",
                        icon: ""
                    },
                    // {
                    //     id: 2.1,
                    //     title: "Upload Merit List",
                    //     type: "item",
                    //     url: "/let/upload-merit-list",
                    //     icon: ""
                    // },
                    {
                        id: 2.2,
                        title: "Upload Documents",
                        type: "item",
                        url: "/let/upload-document",
                        icon: ""
                    },
                    {
                        id: 2.3,
                        title: "Registration List",
                        type: "item",
                        url: "/let/registration-list",
                        icon: ""
                    }
                ]
            },
            {
                id: 3,
                title: "Admission",
                type: "group",
                icon: "",
                url: "/admission",
                visibility: true,
                expand: true,
                children: [
                    {
                        id: 3.1,
                        title: "Counselling",
                        type: "item",
                        url: "/admission/counselling",
                        icon: ""
                    },
                    {
                        id: 3.2,
                        title: "Fee Payment",
                        type: "item",
                        url: "/admission/fee-payment",
                        icon: ""
                    },
                    // {
                    //     id: 3.3,
                    //     title: "Student Documents",
                    //     type: "item",
                    //     url: "/admission/documents",
                    //     icon: ""
                    // },
                    // {
                    //     id: 3.4,
                    //     title: "Upload ICard Image",
                    //     type: "item",
                    //     url: "/admission/upload-icard-image",
                    //     icon: ""
                    // },
                    {
                        id: 3.4,
                        title: "Student Profile",
                        type: "item",
                        url: "/admission/student-profile",
                        icon: ""
                    },
                    // {
                    //   id: 3.3,
                    //   title: "AIL Admission",
                    //   type: "item",
                    //   url: "/admission/ail-admission",
                    //   icon: ""
                    // },
                    // {
                    //     id: 3.6,
                    //     title: "Hostel",
                    //     type: "item",
                    //     url: "/admission/hostel",
                    //     icon: ""
                    // },
                    // {
                    //     id: 3.7,
                    //     title: "Intake Students",
                    //     type: "item",
                    //     url: "/admission/intake-student",
                    //     icon: ""
                    // }
                ]
            }
        ];

        return menu_list;
    }
}