<div class="d-flex">
	<div class="header-btn-lg pe-0">
		<div class="widget-content p-0">
			<div class="widget-content-wrapper">
				<div class="widget-content-left">
					<div class="btn-group" ngbDropdown placement="bottom-right">
						<button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
							<span>
								<div class="icon-wrapper icon-wrapper-alt rounded-circle">
									<!-- <img width="42" src="../../../../../../assets/images/avatars/2.jpg" alt="" class="rounded-circle"> -->
									<i class="pe-7s-keypad icon-gradient bg-ripe-malin"></i>
								</div>
							</span>
						</button>
						<div ngbDropdownMenu class="row">
							<div class="row m-0 p-0">
								<div class="col-md-12 header-dropdwn">Student Management</div>
								<!-- <div class="col-md-4 drop-menu" routerLink="/let">
									<i aria-hidden="true" style="font-size: xx-large;"
										class="pe-7s-home icon-gradient bg-mean-fruit"></i>
									<br /> Dashboard
								</div> -->
								<div class="col-md-6 drop-menu" routerLink="/let">
									<i aria-hidden="true" style="font-size: xx-large;"
										class="pe-7s-news-paper icon-gradient bg-mean-fruit"></i>
									<br /> Lateral Test Exam
								</div>
								<div class="col-md-6 drop-menu" routerLink="/admission">
									<i aria-hidden="true" style="font-size: xx-large;"
										class="pe-7s-users icon-gradient bg-mean-fruit"></i>
									<br />Admission
								</div>
							</div>
							<!-- <div class="dropdown-divider"></div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>