import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';
import { ThemeOptions } from '../../theme-options';
import { Router } from '@angular/router';
import { Menu } from 'src/app/_shared/models';
import { ModuleService } from 'src/app/_shared/services';


@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})

export class BaseLayoutComponent implements OnInit {
  moduleMenus?: Menu;

  @select('config') public config$: Observable<any>;

  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    private router: Router,
    private moduleService: ModuleService
  ) { }

  ngOnInit(): void {
    this.globals.showHeaderIcon = false;
    this.globals.toggleSidebar = false;
    this.moduleMenus = this.moduleService.modulesMenu.find(menu => menu.url === `/${this.router.url.split('/')[1]}`);
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}



