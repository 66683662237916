import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteService } from './_shared/services';

import * as Layout from './_layout';
import { PageNotFoundComponent } from './_shared/components';


const routes: Routes = [
  {
    path: '',
    component: Layout.BaseLayoutComponent,
    canActivateChild: [RouteService],
    loadChildren: () => import('./_modules/lte/lte.module').then(module => module.LteModule)
    // component: Layout.DashboardLayoutComponent,
    // canActivateChild: [RouteService],
    // loadChildren: () => import('./_modules/home/home.module').then(module => module.HomeModule)
  },
  {
    path: 'let',
    component: Layout.BaseLayoutComponent,
    canActivateChild: [RouteService],
    loadChildren: () => import('./_modules/lte/lte.module').then(module => module.LteModule)
  },
  {
    path: 'admission-new',
    component: Layout.BaseLayoutComponent,
    canActivateChild: [RouteService],
    loadChildren: () => import('./_modules/admission/admission.module').then(module => module.AdmissionModule)
  },
  {
    path: 'admission',
    component: Layout.BaseLayoutComponent,
    canActivateChild: [RouteService],
    loadChildren: () => import('./_modules/admissions/admissions.module').then(module => module.AdmissionsModule)
  },
  {
    path: 'authentication',
    component: Layout.PageLayoutComponent,
    loadChildren: () => import('./_modules/authentication/authentication.module').then(module => module.AuthenticationModule)
  },
  {
    path: 'hostel-registration',
    component: Layout.PageLayoutComponent,
    canActivateChild: [RouteService],
    loadChildren: () => import('./_modules/hostel/hostel.module').then(module => module.HostelModule)
  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/authentication/sign_in',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
