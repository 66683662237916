/**
 * DEVELOPMENT ENVIRONMENT
 *
 * PLEASE COMMENT THE LOCAL "api_service_url" AND UNCOMMENT THE LIVE URL FOR FRONTEND DEVELOPMENT
 *
 * DEVELOPER googleOAuthClientId
 * googleOAuthClientId: '994314538211-4i2vrv5noh50gbtf9cbmvts21smk5djv.apps.googleusercontent.com',
 */

export const environment = {
  production: false,
  environment: 'dev',
  //googleOAuthClientId: '854237694033-l2dpsc45n74h0aq6j7dqjkc7jqqqbugc.apps.googleusercontent.com',
  googleOAuthClientId: '994314538211-4i2vrv5noh50gbtf9cbmvts21smk5djv.apps.googleusercontent.com',
  // googleOAuthClientId: '143730934954-5h7hkbd30923mlfm3m2arq49dc1vlh8j.apps.googleusercontent.com',
  api_service_url: 'https://ailapi.cyberschoolmanager.com/api/',
  // api_service_url: 'https://localhost:44397/api/',
  //api_service_url:'https://localhost:7296/api/',
  default_page_size: 50,
  content_type: 'application/json',
  jwt: { jwt_refresh_ttl_hours: 0, jwt_refresh_ttl_minutes: 60 * 30 }
};

