import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, AIOLUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

// LAYOUT
import * as Layout from './_layout';

// MODULES
import { WidgetModule } from './_modules/widget/widget.module';
import { SharedModule } from './_shared/_shared.module';

//import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader';

// const loaderUIConfig: NgxUiLoaderConfig = {
//   blur: 5,
//   delay: 0,
//   fastFadeOut: true,
//   fgsColor: '#D96323',
//   fgsPosition: 'center-center',
//   fgsSize: 60,
//   fgsType: 'folding-cube',
//   gap: 24,
//   masterLoaderId: 'master',
//   overlayBorderRadius: '0',
//   overlayColor: 'rgba(40, 40, 40, 0.8)',
//   pbColor: '#D96323',
//   pbDirection: 'ltr',
//   pbThickness: 3,
//   hasProgressBar: true,
//   maxTime: -1,
//   minTime: 300,
// };



// BOOTSTRAP COMPONENTS
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// GOOGLE AUTH LOGIN
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';

const authServiceConfig: SocialAuthServiceConfig = {
  autoLogin: false,
  providers: [{
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleOAuthClientId)
  }],
  onError: (error) => console.error('Google OAuth Error: ', { ...error, PROVIDER_ID: GoogleLoginProvider.PROVIDER_ID })
}

// INTERCEPTOR
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './app-interceptor.service';
import { SessionBoxComponent } from './_layout/component/header/elements/session-box/session-box.component';
import { ReditectToErpComponent } from './_layout/component/header/elements/reditect-to-erp/reditect-to-erp.component';

@NgModule({
  declarations: [
    // LAYOUT
    AppComponent,
    Layout.BaseLayoutComponent,
    Layout.PageLayoutComponent,
    Layout.DashboardLayoutComponent,
    // Layout.PageTitleComponent,
    // HEADER
    Layout.HeaderComponent,
    Layout.SearchBoxComponent,
    Layout.ModuleBoxComponent,
    Layout.UserBoxComponent,
    // SIDEBAR
    Layout.SidebarComponent,
    // FOOTER
    Layout.FooterComponent,
    SessionBoxComponent,
    ReditectToErpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    SharedModule,
    WidgetModule,
    SocialLoginModule,
    //NgxUiLoaderModule,
  ],
  providers: [
    ConfigActions,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: 'SocialAuthServiceConfig', useValue: authServiceConfig },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private ngRedux: NgRedux<AIOLUIState>,
    private devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(rootReducer, {} as AIOLUIState, [], [devTool.isEnabled() ? devTool.enhancer() : f => f]);
  }
}
