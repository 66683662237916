<div class="app-footer">
	<div class="app-footer__inner">
		<div class="app-footer-right">
			<span class="ps-2"><a href="https://www.ail.ac.in/" target="_blank">Army Institute Of Law</a>
				&copy;2024</span>
		</div>
		<!-- <div class="app-footer-right">
		<a class="btn btn-sm btn-primary me-2" href="javascript:">Homepage</a>
	  </div> -->
	</div>
</div>