import { Component, OnInit, Input } from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  faStar = faStar;
  faPlus = faPlus;

  @Input() heading;
  @Input() icon;
  @Input() breadcrumbs: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
