import { Component, OnInit } from '@angular/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpLoaderService } from '../../services';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit {
  loading: boolean = false;
  loaderText: string = "Loading...";

  constructor(private loaderService: HttpLoaderService) { }

  ngOnInit(): void {
    this.checkOnline$().subscribe(
      (isOnline: boolean) => {
        this.loaderText = !isOnline ? 'No Internet Connection' : 'Loading...';
        this.loading = !isOnline;
      }
    )

    this.loaderService.loaderSubject.subscribe((result: boolean) => { this.loading = result; });
  }

  checkOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
